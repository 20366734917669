<template>
  <div class="container" ref="box">
    <div ref="header" class="header pageHeader">
      <a-page-header title="搜索管理"> </a-page-header>
    </div>
    <div ref="form" class="form">
      <a-form layout="inline" ref="form"> </a-form>
      <a-button class="buttonImg" @click="handleCell('新增', '')">
        <img src="../assets/add.png" alt="" />
        新增
      </a-button>
    </div>
    <a-table
      id="mytb"
      :columns="columns"
      size="middle"
      :scroll="{ x: 700, y: table_h }"
      :pagination="false"
      :data-source="list"
      :row-key="(record) => record.id"
    >
      <span slot="is_show" slot-scope="is_show">
        <a-switch :checked="is_show == 1" disabled />
      </span>
      <span slot="btns" slot-scope="btns, num">
        <a-button
          class="editable-add-btn"
          size="small"
          style="margin: 5px"
          @click="handleCell('编辑', num)"
        >
          编辑
        </a-button>
        <a-button
          class="editable-add-btn"
          size="small"
          style="margin: 5px"
          @click="handleCell('删除', num)"
        >
          删除
        </a-button>
      </span>
    </a-table>
    <!-- <div ref="pagination" style="overflow: hidden">
      <a-pagination
        v-if="pageshow"
        :default-current="currentPage"
        :defaultPageSize="defaultPageSize"
        :page-size-options="pageSizeOptions"
        show-size-changer
        :total="total_num"
        @showSizeChange="onShowSizeChange"
        @change="onPageChange"
        :item-render="itemRender"
      >
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div> -->
    <Edit v-if="$store.state.searchEditShow" />
  </div>
</template>
<script>
import Edit from "@/components/search/edit.vue";
import Sortable from "sortablejs"; //排序
export default {
  name: "User",
  components: {
    Edit,
  },
  data() {
    return {
      columns: [
        {
          title: "名称",
          key: "name",
          dataIndex: "name",
          align: "center",
        },
        {
          title: "展示",
          key: "is_show",
          dataIndex: "is_show",
          scopedSlots: { customRender: "is_show" },
          align: "center",
        },
        {
          title: "备注",
          key: "remark",
          dataIndex: "remark",
          align: "center",
        },
        {
          title: "操作",
          key: "btns",
          dataIndex: "btns",
          scopedSlots: { customRender: "btns" },
          align: "center",
        },
      ],
      table_h: "",
      list: [],
      // pageshow: true,
      // currentPage: 1,
      // total_num: 0,
      // defaultPageSize: this.$store.state.defaultPageSize,
      // pageSizeOptions: this.$store.state.pageSizeOptions,
    };
  },
  // 监听是否刷新列表
  computed: {
    canfreshsearch() {
      return this.$store.state.canfreshsearch;
    },
  },
  watch: {
    canfreshsearch: {
      handler(val) {
        if (val) {
          this.getSearch();
          this.initSortable();
          this.$store.commit("changeCanfreshSearch", false);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getSearch();
    this.initSortable();
  },
  methods: {
    initSortable() {
      var that = this;
      const mytb = document.querySelector("#mytb tbody");
      new Sortable(mytb, {
        handle: ".ant-table-row",
        animation: 150,
        ghostClass: "blue-background-class",
        sort: true,
        onEnd({ newIndex, oldIndex }) {
          var dataSource = that.list;
          var source = dataSource[oldIndex]; // 谁
          //const destination = dataSource.value[newIndex]; // 移动到哪儿
          var pre_pos = "",
            after_pos = "";
          if (newIndex == 0) {
            pre_pos = 0;
            after_pos = dataSource[newIndex].pos;
          } else if (newIndex == that.list.length - 1) {
            after_pos = 0;
            pre_pos = dataSource[newIndex].pos;
          } else {
            if (newIndex < oldIndex) {
              pre_pos = dataSource[newIndex - 1].pos;
              after_pos = dataSource[newIndex].pos;
            } else {
              pre_pos = dataSource[newIndex].pos;
              after_pos = dataSource[newIndex + 1].pos;
            }
          }
          var data = {
            id: source.id,
            pre_pos: pre_pos,
            after_pos: after_pos,
          };
          that.axios
            .post("/api/admin/goodsSearchTag/setSort", data)
            .then((res) => {
              if (that.$code(res)) {
                that.$message.success("排序成功");
                that.getSearch();
                that.initSortable();
              }
            });
        },
      });
    },
    changeTableH() {
      this.table_h =
        this.$refs.box.offsetHeight -
        this.$refs.header.offsetHeight -
        // this.$refs.form.offsetHeight -
        //this.$refs.pagination.offsetHeight -
        45;
      this.$refs.box.style.display = "flex";
      if (this.table_h <= 250) {
        this.table_h = 400;
        this.$refs.box.style.display = "block";
      }
    },
    //获取列表
    getSearch() {
      var that = this;
      that.currentPage = 1;
      that.getList();
    },
    getList() {
      var that = this;
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.axios.get("/api/admin/goodsSearchTag/list").then((res) => {
        that.$message.loading({
          content: "加载中..",
          key: "load",
          duration: 0.00001,
        });
        if (that.$code(res)) {
          var list = res.data.resultObject;
          that.list = list;
        }
      });
    },
    handleCell(operation, num) {
      if (operation == "删除") {
        var that = this;
        this.$confirm({
          title: "提示",
          content: "是否确认删除此微信群？",
          onOk() {
            var data = { id: num.id };
            that.axios.post("/api/admin/weixinGroup/del", data).then((res) => {
              if (that.$code(res)) {
                that.$message.success("删除成功");
                that.getSearch();
                that.initSortable();
              }
            });
          },
          onCancel() {},
        });
      } else {
        if (operation == "新增") {
          this.$store.commit("changeSearchEditShow", true);
          this.$store.commit("changeSearchId", "");
        }
        if (operation == "编辑") {
          this.$store.commit("changeSearchEditShow", true);
          this.$store.commit("changeSearchId", num.id);
        }
      }
    },
  },
};
</script>
<style scoped>
/deep/.pageHeader .ant-page-header,
/deep/.pageHeader .ant-page-header-heading {
  height: auto;
  border: 0 !important;
}
/deep/.ant-form-item {
  margin-right: 10px;
}

/deep/.ant-table-body::-webkit-scrollbar {
  background-color: #fff;
  width: 10px;
  height: 10px;
}
/deep/.ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f2f5;
}
/deep/.ant-pagination .ant-pagination-item,
/deep/.ant-pagination .ant-pagination-jump-prev,
/deep/.ant-pagination .ant-pagination-jump-next {
  display: none;
}
.ant-btn {
  height: 36px;
  border-color: #f8f8f8;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #666;
  border-color: rgba(253, 105, 150);
}
form .ant-btn:hover,
form .ant-btn:focus {
  color: #fff;
}
.ant-table .ant-btn {
  height: 30px;
  padding: 0 20px;
}
</style>